// External depentensies
import React from 'react';

// Local dependensieseact
import { IconProps } from './types';

export default function DefaultClinicIcon({
  width = 74,
  height = 74,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.64">
        <circle cx="37" cy="37" r="37" fill="#EDF1F9" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5992 22.6C22.5992 19.949 24.7483 17.8 27.3992 17.8H46.5992C49.2502 17.8 51.3992 19.949 51.3992 22.6V51.4C52.7247 51.4 53.7992 52.4745 53.7992 53.8C53.7992 55.1255 52.7247 56.2 51.3992 56.2H42.7992C42.2469 56.2 41.7992 55.7523 41.7992 55.2V49C41.7992 47.6745 40.7247 46.6 39.3992 46.6H34.5992C33.2737 46.6 32.1992 47.6745 32.1992 49V55.2C32.1992 55.7523 31.7515 56.2 31.1992 56.2H22.5992C21.2737 56.2 20.1992 55.1255 20.1992 53.8C20.1992 52.4745 21.2737 51.4 22.5992 51.4V22.6ZM29.7992 25H34.5992V29.8H29.7992V25ZM34.5992 34.6H29.7992V39.4H34.5992V34.6ZM39.3992 25H44.1992V29.8H39.3992V25ZM44.1992 34.6H39.3992V39.4H44.1992V34.6Z"
        fill="#D3DDF0"
      />
    </svg>
  );
}
