/* External dependencies */
import { Div, Image, Text } from 'atomize';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import CardWrapper from '@shared/ui/card-wrapper/CardWrapper';
import { EntryType } from '../../../entities/users/lib/types';
import DefaultAvatarIcon from '../../../svg/DefaultAvatarIcon';
import DefaultClinicIcon from '../../../svg/DefaultClinicIcon';
import './style.scss';

type CardInListType = EntryType;

interface CardInListProps {
  imageAlt?: any;
  imageUrl?: any;
  onClick?: Function;
  secondaryText?: any;
  text?: any;
  title?: string;
  type: CardInListType;
}

export default function CardInList({
  imageAlt,
  imageUrl,
  onClick,
  secondaryText,
  text,
  title,
  type,
}: CardInListProps) {
  const { t } = useTranslation();
  const isTypeDoctor = type === EntryType.PHYSICIAN;
  const isTypeClinic = type === EntryType.CLINIC;
  const isTypeSpeciality = type === EntryType.SPECIALTIES;
  const isMoreTwoOfTextLength = text?.length >= 2;
  let translationKey;

  if (text?.length === 2) {
    translationKey = 'receptionPlacesOne';
  } else if (text?.length > 3 && text?.length <= 5) {
    translationKey = 'receptionPlacesTwo';
  } else if (text?.length > 5) {
    translationKey = 'receptionPlacesThree';
  }

  return (
    <CardWrapper onClick={onClick}>
      <Div
        d="flex"
        flexDir={{ xs: 'column', sm: 'row' }}
        className="card-in-list"
        textAlign={{ xs: 'center', sm: 'left' }}
      >
        <Div
          h="inherit"
          d="flex"
          m={{ b: { xs: '20px', sm: '0' } }}
          justify="center"
        >
          {imageUrl ? (
            <Image
              src={imageUrl}
              alt={imageAlt}
              style={{
                width: isTypeDoctor || isTypeClinic ? '74px' : '64px',
                height: isTypeDoctor || isTypeClinic ? '74px' : '64px',
                borderRadius: isTypeDoctor || isTypeClinic ? '50%' : '0',
                objectFit: 'cover',
              }}
            />
          ) : isTypeDoctor ? (
            <DefaultAvatarIcon />
          ) : (
            <DefaultClinicIcon />
          )}
        </Div>
        <Div p={{ l: { xs: '0', sm: '16px' } }}>
          {isTypeDoctor && (
            <Div d="flex" flexWrap="wrap" flexDir={{ xs: 'column', md: 'row' }}>
              {secondaryText?.map((item, index) => {
                const isDotForSecondaryText =
                  secondaryText?.length >= 2 &&
                  secondaryText?.length - 1 !== index;

                return (
                  <Div key={index} d={{ sm: 'flex' }} align={{ sm: 'center' }}>
                    <Text
                      textColor="grayTextInCard"
                      textWeight="500"
                      m={{ b: '4px' }}
                      textAlign={{ xs: 'center', sm: 'left' }}
                    >
                      {item}
                    </Text>
                    {isDotForSecondaryText && (
                      <Text
                        bg="grayTextInCard"
                        w="2px"
                        h="2px"
                        m={{ x: '6px' }}
                        d={{ xs: 'none', md: 'block' }}
                      ></Text>
                    )}
                  </Div>
                );
              })}
            </Div>
          )}
          <Text tag="h2" textSize="display1" m={{ b: '5px' }}>
            {title}
          </Text>
          {isTypeDoctor &&
            (Array.isArray(text) ? (
              <>
                <Text tag="span" d="inline">
                  {text[0]?.clinicName}
                  {isMoreTwoOfTextLength && ', '}
                </Text>
                {isMoreTwoOfTextLength && (
                  <Text tag="span" d="inline">
                    +{text?.length === 2 ? '1' : text?.length}{' '}
                    {t(translationKey)}
                  </Text>
                )}
              </>
            ) : (
              <Text tag="span" d="inline">
                {text}
              </Text>
            ))}
          {isTypeClinic && <Text>{text}</Text>}
          {isTypeSpeciality && (
            <Text textColor="grayTextInCard" textWeight="500">
              {secondaryText}
            </Text>
          )}
        </Div>
      </Div>
    </CardWrapper>
  );
}
