// External dependencies
import { Div } from 'atomize';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Local dependencies
import Search from '@shared/ui/search/Search';

interface SearchBlockProps {
  disabled?: boolean;
  onChange?: (value: any) => void;
  query?: string;
  onClear?: () => void;
}

export default function SearchBlock({
  disabled,
  onChange,
  query,
  onClear,
}: SearchBlockProps) {
  const { t } = useTranslation();

  return (
    <>
      <Div m={{ b: '16px' }} h="55px" style={{ transition: '.3s' }}>
        <Search
          maxWidth="100%"
          placeholder={t('searchForDoctorsAndClinics')}
          value={query}
          onChange={onChange}
          onClear={onClear}
          disabled={disabled}
        />
      </Div>
    </>
  );
}
