/* External dependencies */
import { Container, Div, Icon, Text } from 'atomize';
import { Link, navigate, useScrollRestoration } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import { RootState } from '@app//redux/store';
import {
  useListCategoriesHook,
  useListUsersHook,
} from '@entities/users/hooks/useListUsersHook';
import {
  EntryStatus,
  EntryType,
  FilterEntryType,
} from '@entities/users/lib/types';
import {
  listUsers,
  listUsersCurrentFilterChangeReset,
  listUsersReset,
  listUsersSearchStringChange,
} from '@entities/users/redux/list-users-redux/actions';
import Pagination from '@shared/ui/pagination/Pagination';
import {
  DEFAULT_PAGE_SIZE,
  getItemFromLocalStorage,
  setLocalStorage,
} from '@shared/utils/helpers';
import CardInList from '../cards/CardInList';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import SearchBlock from '../searchBlock/SearchBlock';
import TabForSite from '../tabForSite/TabForSite';
import {
  QueryParamsOfDoctorsClinics,
  returnPathType,
  tabList,
} from './helpers';
import {
  addActiveTab,
  addCurrentPage,
  addSearchString,
  changeUserFilter,
} from './redux/actions';
import { DoctorsAndClinicsState } from './redux/reducer';
import './style.scss';

export default function Users({ location }) {
  const { currentPage, activeTab, searchString } = useSelector(
    (state: RootState): DoctorsAndClinicsState => state.doctorsAndClinics,
  );
  const {
    loading,
    refetch,
    total: usersTotal,
    users = [],
  } = useListUsersHook(
    {
      entryTypes: activeTab !== 'specialties' ? activeTab : '',
      onlyBuilt: true,
      status: EntryStatus.APPROVED,
    },

    (currentPage - 1) * DEFAULT_PAGE_SIZE,
  );
  const {
    categories,
    loading: categoriesLoading,
    refetch: categoriesRefetch,
  } = useListCategoriesHook('');
  const {
    searchString: query,
    total: searchUsersTotal,
    loading: usersSearchLoading,
    users: searchedUsers,
    currentPage: searchedUsersCurrentPage,
  } = useSelector((state: RootState) => state.users);

  const [timer, setTimer] = useState(null);
  const [isCategoryUsersLoading, setIsCategoryUsersLoading] = useState(false);

  const userSlug = getItemFromLocalStorage('userForSpecialist');

  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const queryParamsFilter = queryParams.get('filter') || '';
  const queryParamsCurrentPage = queryParams.get('page') || '';
  const queryParamsSearchQuery = queryParams.get('query') || '';

  const isSpecialties = activeTab === 'specialties';
  const isDoctorOrClinic =
    activeTab === FilterEntryType.PHYSICIAN ||
    activeTab === FilterEntryType.CLINIC;
  const isDoctor = activeTab === FilterEntryType.PHYSICIAN;
  const isClinic = activeTab === FilterEntryType.CLINIC;
  const totalPagesOfSearchedUsers = Math.ceil(searchUsersTotal / 20);
  const totalPagesOfUsers = Math.ceil(usersTotal / 20);
  const isBreadcrumbs = isSpecialties && searchString;
  const isUsers =
    users?.length &&
    isDoctorOrClinic &&
    !searchString &&
    !query &&
    !isSpecialties;
  const isSearchedUsers = searchedUsers?.length && query && !isSpecialties;
  const isUsersForSpecialist =
    users?.length && searchString && isSpecialties && !loading;
  const isNoClinicsOrPhysiciansInSpecialist =
    searchString &&
    !searchedUsers?.length &&
    !usersSearchLoading &&
    isSpecialties;

  const isCategoriesCards =
    categories?.length && !searchString && isSpecialties;
  const isNoCategories =
    !categoriesLoading &&
    !isDoctorOrClinic &&
    !categories?.length &&
    isSpecialties;
  const isPaginationForUsers =
    !isSpecialties && users?.length && !searchString && !query;
  const isPaginationForSearchedUsers =
    !isSpecialties && !usersSearchLoading && searchedUsers?.length && query;
  const currentPageForQueryParams = isPaginationForUsers
    ? currentPage
    : searchedUsersCurrentPage;
  const isLoading =
    (users?.length === 0 && loading && !isSpecialties) ||
    (usersSearchLoading && !isSpecialties) ||
    (isSpecialties && categoriesLoading && !categories?.length) ||
    (loading && isSpecialties && !isCategoriesCards);
  const isNoClinicsOrPhysicians =
    query && !searchedUsers?.length && !isLoading && !isSpecialties;

  useEffect(() => {
    if (isSpecialties && searchString) {
      refetch({
        input: {
          filter: { onlyBuilt: true, status: EntryStatus.APPROVED },
          from: 0,
          query: searchString,
          size: DEFAULT_PAGE_SIZE,
        },
      });
    }

    return () => {
      if (!isSpecialties) {
        dispatch(addSearchString(''));
      }
    };
  }, []);

  useEffect(() => {
    const decodedSearchString = queryParamsSearchQuery
      ? decodeURIComponent(queryParamsSearchQuery)
      : '';

    const shouldAddQueryParams =
      !queryParamsFilter ||
      !queryParamsCurrentPage ||
      queryParamsSearchQuery === null ||
      queryParamsSearchQuery === undefined;

    if (shouldAddQueryParams) {
      changeParamsInURL({
        filter: activeTab,
        page: `${currentPageForQueryParams}`,
        query: query,
      });
    }

    if (queryParamsFilter && activeTab !== queryParamsFilter) {
      dispatch(addActiveTab(queryParamsFilter));
    }

    const shouldUpdateQuery =
      decodedSearchString && query !== decodedSearchString;
    const shouldUpdatePage =
      queryParamsCurrentPage &&
      currentPageForQueryParams !== +queryParamsCurrentPage;

    if (shouldUpdateQuery || shouldUpdatePage) {
      dispatch(addCurrentPage(+queryParamsCurrentPage));
      dispatch(
        listUsers(
          decodedSearchString,
          { entryTypes: queryParamsFilter || activeTab, onlyBuilt: true },
          +queryParamsCurrentPage,
        ),
      );
    }
  }, [queryParamsFilter, queryParamsCurrentPage, queryParamsSearchQuery]);

  useEffect(() => {
    clearTimeout(timer);

    const queryParamsSearchQuery = queryParams.get('query') || '';
    const decodedSearchString = queryParamsSearchQuery
      ? decodeURIComponent(queryParamsSearchQuery)
      : '';

    let newTimer;

    if (query && query !== decodedSearchString) {
      newTimer = setTimeout(() => {
        changeParamsInURL({
          filter: activeTab,
          page: `${1}`,
          query: query,
        });
      }, 500);

      setTimer(newTimer);
    }

    return () => {
      clearTimeout(newTimer);
    };
  }, [query]);

  useEffect(() => {
    if (isSpecialties && isBreadcrumbs && users.length) {
      setIsCategoryUsersLoading(false);
    }

    return () => {
      setIsCategoryUsersLoading(false);
    };
  }, [users]);

  function selectCategory(id) {
    dispatch(addSearchString(id));
    dispatch(addCurrentPage(1));

    changeParamsInURL({
      filter: activeTab,
      page: `${1}`,
      query: '',
    });
    dispatch(listUsersSearchStringChange(''));
    setIsCategoryUsersLoading(true);

    refetch({
      input: {
        filter: { onlyBuilt: true },
        from: 0,
        query: id,
        size: DEFAULT_PAGE_SIZE,
      },
    });

    typeof window !== 'undefined' ? window.scrollTo(0, 0) : null;
  }

  async function changePage(page: number) {
    dispatch(addCurrentPage(page));

    changeParamsInURL({
      filter: activeTab,
      page: `${page}`,
      query: query,
    });

    typeof window !== 'undefined' ? window.scrollTo(0, 0) : null;

    await refetch({
      input: {
        query: '',
        filter: { entryTypes: activeTab, onlyBuilt: true },
        from: (page - 1) * DEFAULT_PAGE_SIZE,
        size: DEFAULT_PAGE_SIZE,
      },
    });
  }

  function handleSelectFilter(value: string) {
    dispatch(changeUserFilter(value));
    dispatch(addCurrentPage(1));
    dispatch(addSearchString(''));
    dispatch(addActiveTab(value));

    changeParamsInURL({
      filter: value,
      page: `${1}`,
      query: value !== FilterEntryType.SPECIALTIES ? query : '',
    });
    dispatch(listUsersCurrentFilterChangeReset());
    if (value === FilterEntryType.SPECIALTIES) {
      categoriesRefetch();
      dispatch(addSearchString(''));
      dispatch(listUsersSearchStringChange(''));
    } else {
      query
        ? dispatch(listUsers(query, { entryTypes: value, onlyBuilt: true }, 1))
        : refetch({
            input: {
              query: '',
              filter: { entryTypes: value, onlyBuilt: true },
              from: currentPage,
              size: DEFAULT_PAGE_SIZE,
            },
          });
    }
  }

  function onSearch(e) {
    const value = e.target.value;

    if (isSpecialties) {
      handleSelectFilter(FilterEntryType.PHYSICIAN);
    }

    dispatch(listUsers(value, { entryTypes: activeTab, onlyBuilt: true }, 1));
  }

  function changePageForSearchedUsers(page: number) {
    changeParamsInURL({
      filter: activeTab,
      page: `${page}`,
      query: query,
    });

    dispatch(
      listUsers(query, { entryTypes: activeTab, onlyBuilt: true }, page),
    );
  }

  function handleClickBreadcrumbs() {
    categoriesRefetch();
    dispatch(addSearchString(''));
    dispatch(listUsersSearchStringChange(''));
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });

    dispatch(addCurrentPage(1));
    dispatch(listUsersSearchStringChange(''));
    changeParamsInURL({
      filter: activeTab,
      page: `${1}`,
      query: '',
    });
  }

  function changeParamsInURL(params: QueryParamsOfDoctorsClinics) {
    const { filter, page, query } = params;
    const queryParamsFilter = queryParams.get('filter');
    const queryParamsPage = queryParams.get('page');
    const queryParamsSearchQuery = queryParams.get('query');
    const decodedSearchString = queryParamsSearchQuery
      ? decodeURIComponent(queryParamsSearchQuery)
      : '';

    const isFilterDifferent = filter !== queryParamsFilter;
    const isPageDifferent = page !== queryParamsPage;
    const isSearchQueryDifferent = query !== decodedSearchString;
    const currentQuery = !isSpecialties ? encodeURIComponent(query) : '';

    if (isFilterDifferent || isPageDifferent || isSearchQueryDifferent) {
      navigate(`?filter=${filter}&page=${page}&query=${currentQuery}`);
    }
  }

  const breadcrumbsList = [
    {
      index: 0,
      value: 'specialties',
    },
    {
      index: 1,
      value: searchString,
    },
  ];

  return (
    <Div d="flex" flexDir="column" minH="100vh">
      <Header />
      <Div
        style={{ flex: '1 1 auto' }}
        bg="#f3f6fa"
        p={{ t: '165px', b: '5rem' }}
      >
        <Container>
          <SearchBlock
            query={query}
            onChange={onSearch}
            onClear={resetSearchField}
          />
          <Div m={{ b: '28px' }}>
            <TabForSite
              items={tabList}
              selectedValue={activeTab}
              onClick={handleSelectFilter}
            />
          </Div>
          <Div>
            {isBreadcrumbs && (
              <Div m={{ b: '20px' }} d="flex" align="center" flexWrap="wrap">
                {breadcrumbsList?.map((item, index) => {
                  const lastItem = breadcrumbsList?.length - 1 === index;

                  return (
                    <>
                      <Div
                        key={item?.index}
                        textColor={lastItem ? 'primary' : 'grayTextInCard'}
                        onClick={lastItem ? null : handleClickBreadcrumbs}
                        cursor={lastItem ? '' : 'pointer'}
                        hoverTextColor={!lastItem && 'black'}
                        d="flex"
                        align="center"
                      >
                        <Trans>{item?.value}</Trans>
                        {lastItem && (
                          <Text tag="span" m={{ l: '0.5rem' }}>
                            (
                            {lastItem ? (
                              loading ? (
                                <Icon name="Loading3" size="20px" />
                              ) : (
                                users?.length
                              )
                            ) : null}
                            )
                          </Text>
                        )}
                      </Div>
                      {lastItem ? null : (
                        <Icon
                          name="RightArrow"
                          color="grayTextInCard"
                          size="12px"
                          m={{ x: '10px' }}
                        />
                      )}
                    </>
                  );
                })}
              </Div>
            )}
            {(isLoading || isCategoryUsersLoading) && (
              <Div d="flex" justify="center" p="50px" shadow="none">
                <Icon name="Loading" size="50px" color="btn_primary_bg" />
              </Div>
            )}
            {isUsers && !isLoading ? (
              <Div
                d="flex"
                style={{ gap: '1rem 0' }}
                flexDir="column"
                {...useScrollRestoration}
              >
                {users?.map((item, index) => {
                  let name = '';
                  let text = [];
                  let type = EntryType.PHYSICIAN;
                  const pathType = returnPathType(item);
                  const url = `/${pathType}/${item?.slug}`;

                  if (item?.typename === EntryType.PHYSICIAN) {
                    name = `${item?.firstName || ''} ${item?.lastName || ''} ${
                      item?.patronymic || ''
                    }`;
                    text = item?.workplaces;
                  } else {
                    name = item?.clinicName;
                    text.push(item?.clinicAddress?.address);
                    type = EntryType.CLINIC;
                  }

                  return item ? (
                    <Link
                      to={url}
                      style={{ display: 'block', color: 'inherit' }}
                      key={index + item?.id}
                      id={item?.slug}
                      onClick={() =>
                        setLocalStorage('userForSpecialist', item?.slug)
                      }
                    >
                      <CardInList
                        key={index}
                        imageUrl={item?.avatar}
                        imageAlt={name}
                        secondaryText={item?.specialities}
                        type={type}
                        title={name}
                        text={text}
                      />
                    </Link>
                  ) : null;
                })}
              </Div>
            ) : null}
            {isSearchedUsers ? (
              <Div
                d="flex"
                style={{ gap: '1rem 0' }}
                flexDir="column"
                {...useScrollRestoration}
              >
                {searchedUsers?.map((item, index) => {
                  let name = '';
                  let text = [];
                  let type = EntryType.PHYSICIAN;
                  const pathType = returnPathType(item);
                  const url = `/${pathType}/${item?.slug}`;

                  if (item?.typename === EntryType.PHYSICIAN) {
                    name = `${item?.firstName || ''} ${item?.lastName || ''} ${
                      item?.patronymic || ''
                    }`;
                    text = item?.workplaces;
                  } else {
                    name = item?.clinicName;
                    text.push(item?.clinicAddress?.address);
                    type = EntryType.CLINIC;
                  }

                  return item ? (
                    <Link
                      to={url}
                      style={{ display: 'block', color: 'inherit' }}
                      key={item?.id + index}
                      id={item?.slug}
                      onClick={() =>
                        setLocalStorage('userForSpecialist', item?.slug)
                      }
                    >
                      <CardInList
                        key={index}
                        imageUrl={item?.avatar}
                        imageAlt={name}
                        secondaryText={item?.specialities}
                        type={type}
                        title={name}
                        text={text}
                      />
                    </Link>
                  ) : null;
                })}
              </Div>
            ) : null}
            {isUsersForSpecialist ? (
              <Div
                d="flex"
                style={{ gap: '1rem 0' }}
                flexDir="column"
                {...useScrollRestoration}
              >
                {users?.map((item, index) => {
                  let name = '';
                  let text = [];
                  let type = EntryType.PHYSICIAN;
                  const pathType = returnPathType(item);
                  const url = `/${pathType}/${item?.slug}`;

                  if (item?.typename === EntryType.PHYSICIAN) {
                    name = `${item?.firstName || ''} ${item?.lastName || ''} ${
                      item?.patronymic || ''
                    }`;
                    text = item?.workplaces;
                  } else {
                    name = item?.clinicName;
                    text.push(item?.clinicAddress?.address);
                    type = EntryType.CLINIC;
                  }

                  return item ? (
                    <Link
                      to={url}
                      style={{ display: 'block', color: 'inherit' }}
                      key={index + item?.id}
                      id={item?.slug}
                      onClick={() =>
                        setLocalStorage('userForSpecialist', item?.slug)
                      }
                    >
                      <CardInList
                        key={index}
                        imageUrl={item?.avatar}
                        imageAlt={name}
                        secondaryText={item?.specialities}
                        type={type}
                        title={name}
                        text={text}
                      />
                    </Link>
                  ) : null;
                })}
              </Div>
            ) : null}
            {(isNoClinicsOrPhysiciansInSpecialist ||
              isNoClinicsOrPhysicians) && (
              <Text
                textSize="subtitle"
                textWeight="600"
                textColor="grayTextInCard"
              >
                {isDoctor && <Trans>physicianNone</Trans>}
                {isClinic && <Trans>clinicNone</Trans>}
              </Text>
            )}
            {isCategoriesCards ? (
              <Div className="specialities-card">
                {categories
                  .filter((category) => category)
                  ?.map((item, index) => {
                    return (
                      item?.description && (
                        <CardInList
                          key={index}
                          imageAlt={item?.title}
                          imageUrl={item?.icon?.url}
                          onClick={() => selectCategory(item?.searchTitle_ru)}
                          title={item?.title}
                          secondaryText={item?.description}
                          type={EntryType.SPECIALTIES}
                        />
                      )
                    );
                  })}
              </Div>
            ) : null}
            {isNoCategories && (
              <Text
                textSize="subtitle"
                textWeight="600"
                textColor="grayTextInCard"
              >
                (<Trans>categoriesNone</Trans>)
              </Text>
            )}
            {isPaginationForUsers && !isLoading ? (
              <Pagination
                currentPage={currentPage}
                itemsCount={totalPagesOfUsers}
                pageSize={1}
                onPageChange={changePage}
              />
            ) : null}
            {isPaginationForSearchedUsers ? (
              <Pagination
                currentPage={searchedUsersCurrentPage}
                itemsCount={totalPagesOfSearchedUsers}
                pageSize={1}
                onPageChange={changePageForSearchedUsers}
              />
            ) : null}
          </Div>
        </Container>
      </Div>
      <Footer />
    </Div>
  );
}
