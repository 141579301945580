/* External dependencies */
import { Col, Container, Div, Row, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import React from 'react';

/* Local dependencies */
import MedcheckLogoSmile from '../../../svg/MedcheckLogoWithSmile';
import './footer.scss';

interface FooterProps {
  className?: string;
  logoColor?: string;
  typographyTextColor?: string;
}

export default function Footer({
  className = 'primary-footer',
  logoColor = '#000',
  typographyTextColor = '#8F9BB3',
}: FooterProps) {
  const data = useStaticQuery(queryIcons);
  const iconIns = getImage(data?.iconInstagram);
  const iconFaceB = getImage(data?.iconFacebook);
  const iconTele = getImage(data?.iconTelegram);
  const iconYouT = getImage(data?.iconYouTube);
  const currentYear = moment().year();

  const menuItems = [
    {
      link: `/`,
      text: <Trans>forPatientsText</Trans>,
    },
    {
      link: `/for-doctors`,
      text: <Trans>forDoctorsAndClinicsText</Trans>,
    },
    {
      link: `/about`,
      text: <Trans>about</Trans>,
    },
    {
      link: '/articles',
      text: <Trans>articles</Trans>,
    },

    {
      link: '/terms-of-use',
      text: <Trans>termsOfUse</Trans>,
    },
  ];
  const iconsFooter = [
    {
      className: 'instagram-link',
      link: 'https://www.instagram.com/medcheck.kg/',
      icon: iconIns,
      sendEventToAnalytics: () => {
        if (window !== undefined && process.env.NODE_ENV === 'production') {
          window.gtag('event', 'click_instagram', {
            event_name: 'click_instagram',
          });
        }
      },
    },
    {
      className: 'facebook-link',
      link: 'https://facebook.com/Medcheck.kg/',
      icon: iconFaceB,
      sendEventToAnalytics: () => {
        if (window !== undefined && process.env.NODE_ENV === 'production') {
          window.gtag('event', 'click_facebook', {
            event_name: 'click_instagram',
          });
        }
      },
    },
    {
      className: 'telegram-link',
      link: 'https://t.me/Medcheck_kg/',
      icon: iconTele,
      sendEventToAnalytics: () => {
        if (window !== undefined && process.env.NODE_ENV === 'production') {
          window.gtag('event', 'click_telegram', {
            event_name: 'click_telegram',
          });
        }
      },
    },
    {
      className: 'youtube-link',
      link: 'https://www.youtube.com/medcheck',
      icon: iconYouT,
      sendEventToAnalytics: () => {
        if (window !== undefined && process.env.NODE_ENV === 'production') {
          window.gtag('event', 'click_youtube', {
            event_name: 'click_youtube',
          });
        }
      },
    },
  ];

  return (
    <Div
      className={className}
      p="50px 0 0px"
      style={{ borderTop: '1px solid #EDF1F7' }}
    >
      <Container>
        <Row align="center" p={{ b: '30px' }}>
          <Col
            size={{ xs: '12', sm: '6', md: '7', lg: '8', xl: '9' }}
            textAlign="left"
          >
            <Link to="/">
              <Text
                tag="span"
                textAlign="center"
                d="inline-block"
                m={{ b: '32px' }}
              >
                <MedcheckLogoSmile fill={logoColor} height={33} width={174} />
              </Text>
            </Link>
            <Text textColor={typographyTextColor} m={{ b: '32px' }}>
              <Text tag="span" d={{ xs: 'block', sm: 'inline' }}>
                Made with ❤ by{' '}
                <a href="https://www.mancho.dev" target="_blank">
                  Mancho
                </a>
                . <br />© {currentYear}
              </Text>
            </Text>
            <Div d="flex" flexDir="row">
              {iconsFooter.map((el, index) => (
                <Div
                  key={index}
                  m={{ r: '16px' }}
                  onClick={el.sendEventToAnalytics}
                >
                  <OutboundLink
                    href={el.link}
                    target="_blank"
                    className={el.className}
                  >
                    <GatsbyImage
                      image={el.icon}
                      alt="instagram"
                      objectFit="cover"
                    />
                  </OutboundLink>
                </Div>
              ))}
            </Div>
          </Col>
          <Col size={{ xs: '12', sm: '6', md: '5', lg: '4', xl: '3' }}>
            <Div m={{ t: '30px' }}>
              {menuItems.map((item, index) => (
                <Div textAlign="left" m={{ y: '10px' }} key={index}>
                  <Link
                    to={item.link}
                    className="footer-menu-item"
                    activeClassName="active"
                  >
                    <Text textSize="body">{item.text}</Text>
                  </Link>
                </Div>
              ))}
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  );
}

export const queryIcons = graphql`
  query {
    iconFacebook: file(relativePath: { eq: "iconFacebook.png" }) {
      childImageSharp {
        gatsbyImageData(width: 35)
      }
    }
    iconInstagram: file(relativePath: { eq: "iconInstagram.png" }) {
      childImageSharp {
        gatsbyImageData(width: 35)
      }
    }
    iconTelegram: file(relativePath: { eq: "iconTelegram.png" }) {
      childImageSharp {
        gatsbyImageData(width: 35)
      }
    }
    iconYouTube: file(relativePath: { eq: "iconYouTube.png" }) {
      childImageSharp {
        gatsbyImageData(width: 45)
      }
    }
  }
`;
