/* External dependencies */
import * as Atomize from 'atomize';
import React from 'react';

/* Local dependencies */
import './style.scss';

export type ButtonTypeColor =
  | 'white'
  | 'primary'
  | 'outlined'
  | 'success'
  | 'danger'
  | 'default'
  | 'transparent'
  | 'primaryDark'
  | 'secondary';

type ButtonTypeTextTransform = 'uppercase' | 'lowercase' | 'capitalize';

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  dataTestid?: string;
  disabled?: Boolean;
  height?: string | object;
  iconSuffix?: string;
  iconPrefix?: string;
  loading?: boolean;
  onClick?: () => void;
  textSize?: string | object;
  shadow?: string;
  style?: any;
  textColor?: string;
  tab?: boolean;
  rounded?: string;
  width?: any;
}

interface CustomButtonProps extends ButtonProps {
  typeColor?: ButtonTypeColor;
  textTransform?: ButtonTypeTextTransform;
}

export default function Button(props: CustomButtonProps) {
  const {
    children,
    className,
    dataTestid,
    disabled,
    iconSuffix,
    height,
    loading,
    onClick,
    typeColor,
    iconPrefix,
    shadow,
    style,
    textColor,
    textTransform,
    textSize,
    tab,
    rounded,
    width,
  } = props;

  return (
    <Atomize.Button
      h={height}
      w={width}
      bg={typeColor ? `btn_${typeColor}_bg` : 'transparent'}
      borderColor={typeColor ? typeColor : 'transparent'}
      border={tab ? { r: '1px' } : 'none'}
      color={textColor}
      className={className}
      data-testid={dataTestid}
      disabled={disabled}
      hoverBg={
        typeColor
          ? `btn_hover_bg_${typeColor === 'white' ? null : typeColor}`
          : 'transparent'
      }
      hoverBorderColor={
        typeColor ? `btn_hover_border_${typeColor}` : 'transparent'
      }
      hoverTextColor={`btn_hover_${typeColor}_text_color`}
      isLoading={loading}
      onClick={onClick}
      prefix={
        iconPrefix && (
          <Atomize.Icon
            className={`${iconPrefix}_prefix`}
            color={textColor}
            name={iconPrefix}
            size="21px"
            m={{ r: '11px' }}
          />
        )
      }
      rounded={rounded ? rounded : '10px'}
      shadow={shadow}
      suffix={
        iconSuffix && (
          <Atomize.Icon
            className={`${iconSuffix}_suffix`}
            color={textColor}
            name={iconSuffix}
            size="21px"
            m={{ l: '5px' }}
          />
        )
      }
      textColor={textColor}
      textTransform={textTransform}
      textWeight="600"
      textSize={textSize}
      style={
        !style
          ? { border: typeColor === 'outlined' && '1px solid #6574CF' }
          : style
      }
    >
      {children}
    </Atomize.Button>
  );
}
