/* External dependencies */
import moment from 'moment';

/* Local dependencies */
import { EntryType, FilterEntryType } from '@entities/users/lib/types';
import { getDateTime } from '@shared/utils/renderDate';
import { WorkDays } from './types';

export function getExperience(experienceSince) {
  const startDate = getDateTime(experienceSince);
  const experienceYear = moment().diff(startDate, 'years');
  let textExperienceYear = 'Стаж ';
  const num = experienceYear;
  const fromElevenToFourteen =
    experienceYear === 11 ||
    experienceYear === 12 ||
    experienceYear === 13 ||
    experienceYear === 14;
  const lastDigit = num % 10;

  if (lastDigit === 0 || experienceYear === 0) {
    textExperienceYear += `менее 1 года`;
  } else if (lastDigit === 1 && experienceYear && !fromElevenToFourteen) {
    textExperienceYear += `${experienceYear} год`;
  } else if (lastDigit <= 4 && experienceYear && !fromElevenToFourteen) {
    textExperienceYear += `${experienceYear} года`;
  } else if (!experienceYear) {
    textExperienceYear += 'не указан';
  } else {
    textExperienceYear += `${experienceYear} лет`;
  }

  return textExperienceYear;
}

export function getWorkPlaces(user) {
  let workPlaces = user?.workplaces;

  if (user?.__typename === EntryType.CLINIC) {
    const workPlace = {
      services: [...(user?.services || '')],
      clinicName: user?.clinicName_ru,
      clinicAddress: { ...user?.clinicAddress },
    };

    workPlaces = [workPlace];
  }

  return workPlaces;
}

export function getSlicedArray(arr) {
  const newArr = arr?.length > 3 ? arr?.slice(0, 3) : arr;

  return newArr;
}

export function returnPathType(user) {
  return user?.__typename ? user?.__typename.toLocaleLowerCase() : 'search';
}

export const tabList = [
  {
    label: FilterEntryType.SPECIALTIES,
  },
  {
    label: FilterEntryType.PHYSICIAN,
  },
  {
    label: FilterEntryType.CLINIC,
  },
];

export interface QueryParamsOfDoctorsClinics {
  filter: string;
  page: string;
  query: string;
}

export function getDaysWithData(workDays: WorkDays) {
  const objectWithData = {};
  for (const day in workDays) {
    if (workDays[day] !== null && workDays[day] !== undefined) {
      objectWithData[day] = workDays[day];
    }
  }

  return Object.keys(objectWithData).length > 0 ? objectWithData : null;
}

export function getTodaySchedule(schedule: WorkDays) {
  if (!schedule) return '-';

  const days = Object.keys(schedule);
  const currentDay = days[moment().day() - 1];

  if (!schedule[currentDay]?.workingHours) return '-';

  const currentSchedule = schedule[currentDay]?.workingHours[0];

  if (!currentSchedule) return '-';

  const startTime = getWorkTime(currentSchedule?.from);
  const finishTime = getWorkTime(currentSchedule?.to);

  return `${startTime} - ${finishTime}`;
}

export function getWorkTime(workDate) {
  if (!workDate || typeof workDate !== 'number') return '-';

  const todayWorkDate = new Date(workDate * 1000);
  const todayWorkHour = todayWorkDate.getHours();
  const todayWorkMinute = getWorkHourMinutes(todayWorkDate);

  return `${todayWorkHour}:${todayWorkMinute}`;
}

export function getWorkHourMinutes(workHours) {
  if (!workHours) return '';

  return workHours.getMinutes() < 10
    ? '0' + workHours.getMinutes()
    : workHours.getMinutes();
}
