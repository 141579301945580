/* External dependencies */
import { useQuery } from '@apollo/client';

/* Local dependencies */
import { anonymousClient, setAnonymousClient } from '@app/clients/medcheck';
import { listCategoriesWithApolloClient } from '@entities/medical-specialties/redux/queries';
import { DEFAULT_PAGE_SIZE } from '@shared/utils/helpers';
import {
  DEFAULT_SORT,
  ListUsersFilter,
} from '../redux/list-users-redux/actions';
import { listUsersWithApolloClient } from '../redux/list-users-redux/queries';

const MAX_BATCH_GET_SIZE = 1000;

export const useListUsersHook = (
  filter: ListUsersFilter,
  currentPage = 0,
  searchString?,
) => {
  const { error, data, loading, refetch } = useQuery(
    listUsersWithApolloClient,
    {
      client: anonymousClient || setAnonymousClient(),
      variables: {
        input: {
          filter,
          from: currentPage,
          size: DEFAULT_PAGE_SIZE,
          query: searchString,
          sort: DEFAULT_SORT,
        },
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  );

  const users = data?.listUsers?.users;
  const total = data?.listUsers?.total;

  return {
    error,
    users,
    loading,
    refetch,
    total,
  };
};

export const useListCategoriesHook = (searchString: string) => {
  const { error, data, loading, refetch, variables } = useQuery(
    listCategoriesWithApolloClient,
    {
      client: anonymousClient || setAnonymousClient(),
      variables: {
        input: {
          query: searchString,
          from: 0,
          size: MAX_BATCH_GET_SIZE,
        },
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
      notifyOnNetworkStatusChange: true,
    },
  );

  const categories = data?.listCategories?.categories;

  return {
    error,
    categories,
    loading,
    refetch,
  };
};
