/* External dependencies */
import { Div } from 'atomize';
import React from 'react';

interface CardWrapperProps {
  children: any;
  onClick?: Function;
  padding?: string;
}

export default function CardWrapper({
  children,
  onClick,
  padding = '24px 16px',
}: CardWrapperProps) {
  return (
    <Div
      cursor={onClick && 'pointer'}
      onClick={onClick}
      p={padding}
      bg="#fff"
      rounded="tenPixel"
      minH="122px"
    >
      {children}
    </Div>
  );
}
