/* External dependencies */
import { ApolloProvider } from '@apollo/client';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

/* Local dependencies */
import { anonymousClient, setAnonymousClient } from '@app/clients/medcheck';
import { RootState } from '@app/redux/store';
import Users from '../../components/website/doctors-clinics/DoctorsClinics';
import Layout from '../../shared/ui/layout/WebLayout';

export default function DoctorAndClinicDetail({ data, location = {} }) {
  const { client } = useSelector((state: RootState) => state.login);
  const { t } = useI18next();
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', t('doctorsClinics'))}</title>
      </Helmet>
      <ApolloProvider
        client={client ? client : anonymousClient || setAnonymousClient()}
      >
        <Users location={location} />
      </ApolloProvider>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
