// Externall dependencies
import { Div } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import Button from '@shared/ui/button';
import './style.scss';

export interface FilterType {
  label: string;
  value?: string;
}

interface TabForSiteProps {
  items: FilterType[];
  onClick: (value: string) => void;
  selectedValue: string;
}

export default function TabForSite({
  items,
  onClick,
  selectedValue,
}: TabForSiteProps) {
  function handleSelectFilter(value: string) {
    onClick(value);
  }

  return (
    <Div
      d="flex"
      className="tab-for-site"
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      w={{ sm: 'fit-content' }}
    >
      {items.map(({ label }, index) => {
        const isSelected = selectedValue === label;

        return (
          <Button
            typeColor={isSelected ? 'primary' : 'default'}
            textColor={isSelected ? 'white' : 'grayTextInCard'}
            onClick={() => handleSelectFilter(label)}
            height={{ xs: '50px', sm: '40px' }}
            key={index}
          >
            <Trans>{label}</Trans>
          </Button>
        );
      })}
    </Div>
  );
}
